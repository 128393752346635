/* eslint-disable */
import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography /*, useTheme */,
} from "@mui/material";
import {
  Fullscreen,
  FullscreenExit,
  PauseCircle,
  PlayCircle /* , Stop */,
} from "@mui/icons-material";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { useTranscriptionDisplay } from "../../../context/TranscriptionDisplay.tsx";
import { useUsageControlContext } from "../../../context/UsageControl.tsx";

type Props = {
  isActive: boolean;
  onStart?: () => void;
  onPause?: () => void;
  // onStop?: () => void
};
export default function HeaderControlsSection({ isActive }: Props) {
  // const theme = useTheme()
  const { setShowHeader } = useTranscriptionDisplay();
  const { limitReached } = useUsageControlContext();
  const lastEventIdRef = React.useRef<string | null>(null);
  const inactivityTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(
    !!document.fullscreenElement
  );
  const {
    startTranscription,
    transcriptionStatus,
    pauseTranscription,
    unpauseTranscription,
    eventId,
    stopTranscription,
  } = useTranscriptionContext();

  const handleFullScreenChange = React.useCallback(() => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  React.useEffect(() => {
    if (lastEventIdRef.current === eventId) return;
    if (transcriptionStatus === "paused" || transcriptionStatus === "started") {
      stopTranscription().then(() => {
        startTranscription();
        lastEventIdRef.current = eventId;
      });
    }
    lastEventIdRef.current = eventId;
  }, [eventId, startTranscription, stopTranscription, transcriptionStatus]);

  const handleInactivity = () => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
    }

    inactivityTimeout.current = setTimeout(() => {
      setShowHeader(false);
      window.removeEventListener("mousemove", handleInactivity);
    }, 2000);
  };

  const listenForInactivity = () => {
    window.addEventListener("mousemove", handleInactivity);
  };

  const makeFullScreen = async () => {
    const elem = document.documentElement;
    if (document.fullscreenElement) return;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      //@ts-expect-error
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      //@ts-expect-error
      elem.webkitRequestFullscreen();
      //@ts-expect-error
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      //@ts-expect-error
      elem.msRequestFullscreen();
    }
  };

  const minimizeFullScreen = async () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
    if (document.exitFullscreen) {
      document.exitFullscreen();
      //@ts-expect-error
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      //@ts-expect-error
      document.webkitExitFullscreen();
      //@ts-expect-error
    } else if (document.msExitFullscreen) {
      /* IE11 */
      //@ts-expect-error
      elem.msExitFullscreen();
    }
  };

  const handleTranscriptionStartClick = () => {
    startTranscription();
    listenForInactivity();
    makeFullScreen();
  };

  const handleTranscriptionPauseClick = () => {
    pauseTranscription();
  };

  const handleTranscriptionPlayClick = () => {
    unpauseTranscription();
    listenForInactivity();
    // makeFullScreen()
  };

  // const handleTranscriptionStopClick = () => {
  //     stopTranscription()
  // }

  return (
    <RunMeetingHeaderSection label="">
      {transcriptionStatus === "unstarted" ||
        transcriptionStatus === "starting" ? (
        // <Button
        //     onClick={handleTranscriptionStartClick}
        //     variant='contained'
        //     disabled={transcriptionStatus === 'starting' || limitReached}
        //     startIcon={transcriptionStatus === 'starting' ? <CircularProgress color="inherit" size={15} /> : null}
        // >Present</Button>

        <IconButton
          color="primary"
          onClick={handleTranscriptionStartClick}
          disabled={transcriptionStatus === "starting" || limitReached}
        >
          {transcriptionStatus === "starting" ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            <PlayCircle />
          )}
        </IconButton>
      ) : transcriptionStatus === "stopped" ? (
        <Typography>Transcription Stopped</Typography>
      ) : (
        <Stack direction="row">
          {transcriptionStatus === "paused" ? (
            <IconButton color="primary" onClick={handleTranscriptionPlayClick}>
              <PlayCircle />
            </IconButton>
          ) : transcriptionStatus === "started" ? (
            <IconButton color="primary" onClick={handleTranscriptionPauseClick}>
              <PauseCircle />
            </IconButton>
          ) : null}
          {/* Commented out stop button */}
          {/* <IconButton
                    onClick={handleTranscriptionStopClick}
                ><Stop sx={{
                    color: theme.palette.error.main
                }} /></IconButton> */}
        </Stack>
      )}
      {isFullScreen ? (
        <Tooltip title="Exit fullscreen">
          <IconButton onClick={minimizeFullScreen}>
            <FullscreenExit />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Make fullscreen">
          <IconButton onClick={makeFullScreen}>
            <Fullscreen />
          </IconButton>
        </Tooltip>
      )}
    </RunMeetingHeaderSection>
  );
}
