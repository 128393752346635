import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals.ts";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import RunMeeting from "./containers/RunMeeting/RunMeeting.tsx";
import { TranscriptionDisplayProvider } from "./context/TranscriptionDisplay.tsx";
import SubscriberPage from "./containers/SubscriberPage/SubscriberPage.tsx";
import { UsageControlProvider } from "./context/UsageControl.tsx";
import EmptyMeeting from "./containers/RunMeeting/EmptyMeeting.tsx";

declare module "@mui/material/styles" {
  interface Theme {
    charcoal: {
      main: "#403F3E";
      light: "#EDEBEA";
      dark: "#1F1E1D";
      contrastText: "#ffffff";
    };
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/event",
        element: <EmptyMeeting />,
      },
      {
        path: "/event/:id",
        element: <RunMeeting />,
      },
      {
        path: "/transcript",
        element: <SubscriberPage />,
      },
      {
        path: "/transcript/:transcriptId",
        element: <SubscriberPage />,
      },
    ],
  },
]);

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  shape: {
    borderRadius: 10, // Change this value to adjust the border radius
  },
  palette: {
    mode: "dark",
    background: {
      default: "#333333",
      paper: "#1f1e1d",
    },
    primary: {
      main: "#fe8902",
      light: "#F9DEB5",
      dark: "#DB6E1B",
      contrastText: "#1f1e1d",
    },
    secondary: {
      main: "#228EFF",
      light: "#BDE1FF",
      dark: "#2449BB",
      contrastText: "#FFFFFF",
    },
    // charcoal: {
    //   main: '#403F3E',
    //   light: '#EDEBEA',
    //   dark: '#1F1E1D',
    //   contrastText: '#ffffff',
    // },
    error: {
      main: "#fe8902",
      light: "#FFC8BA",
      dark: "#CC0000",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#22B420",
      light: "#C8EDC4",
      dark: "#009000",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "40px", // This sets the border radius to be fully rounded
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <TranscriptionProvider transcriptionType="caption-only"> */}
    <TranscriptionDisplayProvider>
      <UsageControlProvider>
        <RouterProvider router={router} />
      </UsageControlProvider>
    </TranscriptionDisplayProvider>
    {/* </TranscriptionProvider> */}
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
