/* eslint-disable */
import { Autocomplete, Box, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { languageOptions } from "../../RunMeeting/components/HeaderTranscriptionLanguage.tsx";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";

type Props = {
  open: boolean;
  onLanguageSelect: (lang: string) => void;
  selectedLanguage?: string;
  onClose: () => void;
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SubscriberLanguageModal({
  open,
  selectedLanguage,
  onLanguageSelect,
  onClose,
}: Props) {
  const [error, setError] = React.useState<string>("");
  const { currentEvent } = useTranscriptionContext();
  const languages = React.useMemo(() => {
    if (!currentEvent) return [];
    return currentEvent?.target_languages.map((lang) => {
      return {
        label: languageOptions.get(lang) as string,
        id: lang,
        valid: true,
      };
    });
  }, [currentEvent]);

  const handleClose = () => {
    if (!error) {
      onClose();
    }
  };
  return (
    <Modal
      open={open || !!error}
      disableAutoFocus
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom="20px"
        >
          Select a language
        </Typography>
        <Autocomplete
          value={
            languages.find((lang) => lang.id === selectedLanguage) ??
            languages[0]
          }
          onChange={(
            event: any,
            newValue: { label: string; id: string; valid: boolean } | null
          ) => {
            onLanguageSelect(newValue?.id ?? "en-AU");
            if (newValue?.valid) {
              setError("");
            } else {
              setError("This language is unavailable but it's coming soon!");
            }
          }}
          disableClearable
          options={languages}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              helperText={error ? error : undefined}
              error={!!error}
              {...params}
              label="Language"
            />
          )}
        />
      </Box>
    </Modal>
  );
}
